export interface BuildConfig {
  plugin_id: string;
  dealer_id: string;
  autoapr_dealer_id: string;
  plugin_ajax_url: string;
  plugin_server: string;
  es_url: string;
  country: "US" | "CA";
  plugin_config: any;
  plugin_data: any;
  sellnow_config: any;
  api_enabled: boolean;
  dealer_superlative_config: any;
  plugin_location_configs: any;
  global_styles: string;
  ga_id: string | undefined;
  collect_survey: boolean;
  superlative_global_css: string;
  superlative_config: any;
  autoapr_config: any;
  vdp_tracking_config: any;
}
const config: BuildConfig = {
  plugin_id: process.env.PLUGIN_ID,
  dealer_id: process.env.DEALER_ID,
  autoapr_dealer_id: process.env.AUTOAPR_DEALER_ID,
  plugin_ajax_url: process.env.PLUGIN_AJAX_URL,
  plugin_server: process.env.PLUGIN_SERVER,
  es_url: process.env.ES_URL,
  country: process.env.COUNTRY as any,
  plugin_config: process.env.PLUGIN_CONFIG,
  plugin_data: process.env.PLUGIN_DATA,
  sellnow_config: process.env.SELLNOW_CONFIG,
  api_enabled: process.env.API_ENABLED as unknown as boolean,
  dealer_superlative_config: process.env.DEALER_SUPERLATIVE_CONFIG,
  plugin_location_configs: process.env.PLUGIN_LOCATION_CONFIGS,
  global_styles: process.env.GLOBAL_STYLES,
  ga_id: process.env.GA_ID,
  collect_survey: process.env.COLLECT_SURVEY as unknown as boolean,
  superlative_global_css: process.env.SUPERLATIVE_CSS,
  superlative_config: process.env.SUPERLATIVE_CONFIG,
  autoapr_config: process.env.AUTOAPR_CONFIG,
  vdp_tracking_config: process.env.VDP_TRACKING_CONFIG,
};

export default config;
