import { IconSet, SuperlativeRule } from "../../../server/models";

declare global {
  interface Window {
    ga?: any;
    gaplugins?: any;
    gtag?: any;
    dataLayer?: any;
    TradePendingReloadSuperlatives?: boolean;
    tradepending_location?: string;
    DDC?: {
      API?: any;
    };
    jQuery?: {};
    TradePendingPlugin?: any;
    attachEvent?: (name: string, cb: any) => void;
    digitalData?: any;

    Promise: typeof Promise;

    tradependingSetup?: () => void;
    tradendingLaunchSnapOverlay?: (vehicle: any) => void;
    tradependingLaunchSnapOverlay?: (vehicle: any) => void;
    tradependingConversionEvent?: (event: string) => void;
    tradependingReportRun?: (vehicle: any, pricing: any) => void;
    tradependingVehicleSelected?: (vehicle: any) => void;
    _satellite?: {
      track: (event: string) => void;
    };
    AutoLeadStarEvent?: (num: number, event: string, data: any) => void;
    trafficscore?: any;
    traffic_score_data?: any;
    SMCX?: any;
    _pxam?: any[];
    sd?: (event: string, data?: any) => void;
    requested_location?: string;
    $ae?: any;
  }
}

export type LocationConfig = {
  ajax_url?: string;
  force_reload_on_ajax?: OnBeforeUnloadEventHandler;
  observer_selector?: string;
  observer_selector_reinit?: string;
  watch_hash_change?: boolean;
  has_been_setup?: boolean;
  new_url_regex?: string;
  used_url_regex?: string;
  last_updated?: Date;
  location_type?: string;
  url_regex?: string;
  custom_url_regex?: string;
};

export type LocationConfigs = {
  [K in "srp" | "vdp" | "home" | "landing" | string]: LocationConfig;
};

export type DealerSuperlativeConfig = {
  new_car_superlatives?: boolean;
  icon_set?: IconSet;
  new_disallowed_superlatives?: string[];
  used_disallowed_superlatives?: string[];
  superlative_rules?: SuperlativeRule[];
  no_pricing_in_texas?: boolean;
  use_superlative_report?: boolean;
  ignore_regex?: string;
};

export type SuperlativeConfig = {
  status?: string;
  updated_at?: Date;
  flat_icons: boolean;
  flat_circle_icons: boolean;
  color_circle_icons: boolean;
  locations: {
    vdp: {
      css: string;
      moreinfo_css: string;
      template_css?: boolean;
    };
  };

  allow_config?: boolean;
  user_id?: string;
  demo: string | boolean;
};

export type DdcCtaPluginConfig = {
  type: string;
  target: string;
  onclick: () => void;
  text: {};
  style: string;
  classes?: string;
  imgClasses?: string;
  imgSrc?: string;
  imgAlt?: string;
};

export enum ExtractedItem {
  VIN,
  STOCK_NUMBER,
  PRICE,
}

export enum VehicleCondition {
  USED = 1,
  NEW,
  CERTIFIED,
  UNKNOWN = 999,
}

export enum CacheItemType {
  SUPERLATIVE,
  VEHICLE_CONDITION,
  STOCK_NUMBER_VIN,
}
